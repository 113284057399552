import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

export default function Remind() {
  const navigate = useNavigate();
  const { doc } = useOutletContext();
  const [message, setMessage] = useState("");
  const [err, setErr] = useState();

  async function remind(event) {
    event.preventDefault();
    try {
      let res = (await axios.post(`${process.env.REACT_APP_API_URL}/docs/${doc?.docid}/remind`, {message: message})).data;
      navigate("finish");
    } catch (err) {
      console.error(err);
      setErr(err.response?.data);
    }
  }

  return (
    <Modal show={true} size="lg" onHide={() => navigate("..")} backdrop="static">
      <Modal.Header className="bg-light" closeButton>
        <Modal.Title>Remind signers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="RemindForm" onSubmit={remind} className="mb-3">
          <p>Names and emails of signers that still need to sign the document <b>{doc?.name}</b>.</p>
          {doc?.signers.filter(signer => signer.state === 1).map(signer =>
            <Stack key={signer.signerid} direction="horizontal" className="mb-2" gap={2}>
              <FloatingLabel label="Name" className="flex-fill">
                <Form.Control disabled type="name" placeholder="Name" className="bg-light" value={signer.name} />
              </FloatingLabel>
              <FloatingLabel label="Email" className="flex-fill">
                <Form.Control disabled type="email" placeholder="Email" className="bg-light" value={signer.email} />
              </FloatingLabel>
            </Stack>)}
          <Form.Group className="mt-3">
            <Form.Label>Reminder message:</Form.Label>
            <Form.Control as="textarea" placeholder="Reminder message" className="bg-light" style={{minHeight: "9rem"}}
              value={message} onChange={event => setMessage(event.target.value)} />
          </Form.Group>
        </Form>
        {err?.message && <Alert variant="danger">{err?.message}</Alert>}
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button as={Link} to=".." variant="light" className="me-auto">Cancel</Button>
        <Button variant="dark" className="orange" type="submit" form="RemindForm">
          <FontAwesomeIcon icon={faBell} /> Remind
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
