import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Stack from "react-bootstrap/Stack";
import { useAuth } from "../AuthContext";
import { capitalize, isImg, isInputtype, inputs, drawSizes } from "../utils";
import { isSafari } from 'react-device-detect'

export default function EditInput({ doc, setDoc, docRef, input, currentInput, setCurrentInput, prevInput,
  setResizeInput, setCurrentSigner, setFontsize, scale = 1 }) {
  const navigate = useNavigate();
  const [user] = useAuth();
  const [start, setStart] = useState([0, 0, 0]);
  const [signer, setSigner] = useState({});
  const [dragging, setDragging] = useState(false);
  const [changed, setChanged] = useState(false);
  const [popPlace, setPopPlace] = useState("top");
  const [signerDrop, setSignerDrop] = useState(false);
  const [typeDrop, setTypeDrop] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [placement, setPlacement] = useState('top');

  const inputRef = useRef();
  let timeout = null;

  useEffect(() => {
    let signer = doc.signers.find(s => s.signerid === input.signerid);
    setSigner(signer);
  }, [input.signerid, doc.signers])

  useEffect(() => {
    if (changed && input) {
      update();
      setChanged(false);
    }
  }, [changed]);  

  function update() {
    if (input.y < 40) {
      setPlacement("bottom");
    } else {
      setPlacement("top");
    }

    if (
      input.page == null ||
      input.x < 0 ||
      input.x + input.width > inputRef.current.parentElement.clientWidth ||
      input.y < 0 ||
      input.y + input.height > inputRef.current.parentElement.clientHeight
    ) {
      axios.delete(`${process.env.REACT_APP_API_URL}/inputs/${input.inputid}`);
      setDoc({ ...doc, inputs: doc.inputs.filter((inpt) => inpt !== input) });
    } else {
      axios.put(`${process.env.REACT_APP_API_URL}/inputs/`, input);
    }
  }

  function dragstart(event) {
    console.log("start drag")
    setCurrentInput(input);
    setStart([event.clientX, event.clientY, docRef.current.scrollTop]);
    setDragging(true);
  }

  function dragend(event) {
    input.x = input.x * scale + event.clientX - start[0];
    input.y = input.y * scale + event.clientY - start[1] + docRef.current.scrollTop - start[2];
    if (input.x < 0) input.x = scale;
    if (input.x + input.width * scale > inputRef.current.parentElement.clientWidth)
      input.x = inputRef.current.parentElement.clientWidth - input.width * scale;
    if (input.y < 0) input.y = scale;
    let heightOffset = isImg(input) ? input.height * scale : 2 * input.fontsize * scale;
    if (input.y + heightOffset > inputRef.current.parentElement.clientHeight)
      input.y = inputRef.current.parentElement.clientHeight - heightOffset;
    input.x /= scale;
    input.y /= scale;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input ? input : inpt)});
    setDragging(false);
    console.log("end drag")
    setChanged(true);
    update();
  }

  function resizestart(event) {
    input.start = {};
    input.start.x = input.x;
    input.start.y = input.y;
    input.start.clientX = event.clientX;
    input.start.clientY = event.clientY;
    input.start.width = input.width;
    input.start.height = input.height;
    setResizeInput(input);
  }

  function menuEnter(event) {
    window.clearTimeout(timeout);
    if (currentInput?.inputid !== input.inputid) {
      setCurrentInput(input);
    }
  }

  function menuLeave() {
    if (!dragging) {
      timeout = window.setTimeout(() => setCurrentInput(null), 800);
    }
  }

  async function deleteInput() {
    try {
      axios.delete(`${process.env.REACT_APP_API_URL}/inputs/${input.inputid}`);
      setDoc({...doc, inputs: doc.inputs.filter(inpt => inpt !== input)});
      setCurrentInput(null);
    } catch (err) {
      console.error(err);
    }
  }

  function changeInputType(index) {
    input.type = index;
    input.width = drawSizes(input).width;
    input.height = drawSizes(input).height;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt.inputid === input.inputid ? input : inpt)});
    setChanged(true);
  }

  function changeFontsize(event) {
    let fontsize = event.target.value;
    if (!isImg(input) && fontsize >= 6 && fontsize <= 30) {
      setDoc({...doc, inputs: doc.inputs.map(inpt => inpt === input
      ? {...input, fontsize: fontsize} : inpt)});
      setChanged(true);
      setFontsize(fontsize);
    }
  }

  function resize(event) {
    input.height = event.target.clientHeight / scale;
    setChanged(true);
    console.log(input.height);
  }

  function setText(event) {
    input.textdata = event.target.value;
  
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
  
    const newTimeout = setTimeout(() => {
      setDoc({...doc, inputs: doc.inputs.map(inpt => inpt.inputid === input.inputid ? input : inpt)});
      setChanged(true);
    }, 400);
  
    setTypingTimeout(newTimeout);
  }

  function setSignature(event) {
    event.stopPropagation();
    if ((inputs[input.type] === "signature" && !user.signature)
     || (inputs[input.type] === "initial" && !user.initial)) navigate("draw");
    input.filled = !input.filled;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt.inputid === input.inputid
      ? input : inpt)});
    axios.put(`${process.env.REACT_APP_API_URL}/inputs/`, input);
  }

  function checkboxInputLabel() {
    const inputLen = Math.round(input.width / (input.fontsize * 0.6)) - 3;
    const inputLabel = capitalize(inputs[input.type]);
    return inputLabel.length > inputLen ? `${inputLabel.substring(0, inputLen)}...` : inputLabel;
  }

  function check(event) {
    input.filled = event.target.checked;
    setDoc({...doc, inputs: doc.inputs.map(inpt => inpt.inputid === input.inputid ? input : inpt)})
    axios.put(`${process.env.REACT_APP_API_URL}/inputs/`, input);
  }

  function renderInput() {
    if (isImg(input)) {
      return (
        <img id={`i${input.inputid}`} ref={inputRef} draggable={!isSafari} alt={capitalize(inputs[input.type])} title={capitalize(inputs[input.type])}
          width={input.width * scale} height={input.height * scale}
          className={`position-absolute opacity-75 border
            ${input.required && "input-borders"} rounded bg-light editinput`}
          style={{left: input.x * scale, top: input.y * scale}}
          src={input.filled ? user[inputs[input.type]] : ""}
          onDragStart={dragstart} onDragEnd={dragend} onMouseEnter={menuEnter} onMouseLeave={menuLeave} onClick={setSignature} />
      );
    }
    if (isInputtype(input, "checkbox")) {
      return (
        <div id={`i${input.inputid}`} ref={inputRef} className="position-absolute opacity-75" draggable={!isSafari}
          style={{left: input.x * scale, top: input.y * scale, width: input.width * scale, fontSize: input.fontsize * scale}}
          onClick={event => event.stopPropagation()}
          onDragStart={dragstart} onDragEnd={dragend} onMouseEnter={menuEnter} onMouseLeave={menuLeave}>
          <Form.Check type="checkbox" className={`editinput ${input.required && "input-borders"}`} draggable={!isSafari}
            style={{fontSize: input.fontsize * scale}} checked={input.filled ?? false} onChange={check}
            label={checkboxInputLabel()} />
        </div>
      );
    }
    if (isInputtype(input, "paragraph")) {
      return (
        <div id={`i${input.inputid}`} ref={inputRef} className="position-absolute opacity-75" draggable={!isSafari}
          style={{left: input.x * scale, top: input.y * scale, width: input.width * scale, fontSize: input.fontsize * scale}}
          onClick={event => event.stopPropagation()}
          onDragStart={dragstart} onDragEnd={dragend} onMouseUp={resize} onMouseEnter={menuEnter} onMouseLeave={menuLeave}>
          <Form.Control as="textarea" className={`bg-light ${input.required && "input-borders"} editinput`}
            placeholder={capitalize(inputs[input.type])} style={{fontSize: input.fontsize * scale, height: input.height * scale}}
            value={input.textdata ?? ""} onChange={setText} autoFocus />
        </div>
      );
    }
    return (
      <div id={`i${input.inputid}`} ref={inputRef} className="position-absolute opacity-75" draggable={!isSafari}
        style={{left: input.x * scale, top: input.y * scale, width: input.width * scale, fontSize: input.fontsize * scale}}
        onClick={event => event.stopPropagation()}
        onDragStart={dragstart} onDragEnd={dragend} onMouseEnter={menuEnter} onMouseLeave={menuLeave}>
        <Form.Control type={inputs[input.type]} className={`bg-light ${input.required && "input-borders"} editinput`}
          placeholder={capitalize(inputs[input.type])} style={{fontSize: input.fontsize * scale }}
          value={input.textdata ?? ""} onChange={setText} autoFocus />
      </div>
    );
  }

  return <>
    <OverlayTrigger show={!dragging && currentInput?.inputid === input.inputid} placement={placement} overlay={
      <Popover className="bg-light" style={{display: "inline-table", minWidth: "fit-content"}}
        onMouseEnter={menuEnter} onMouseLeave={menuLeave}
        onClick={event => {
          event.stopPropagation();
          setSignerDrop(false);
          setTypeDrop(false);
        }}>
        <Popover.Header className="header-input h-48">
          <span className="vertical-align">{capitalize(inputs[input.type])}</span>
          <Button onClick={deleteInput} className="header-delete float-right" size="sm">
          <span className="material-icons-outlined font-size-input mt-1">delete_forever</span>
          </Button>
        </Popover.Header>
        <Popover.Body as={Stack} gap={2} direction="horizontal" className="p-2 body-input">
          <DropdownButton show={typeDrop} title={<div className="auth-icons"><span className="material-icons-outlined sidebar-icon">edit</span><span className="package-text-span"> Change type</span></div>} variant="outline-dark" size="sm"
            onToggle={(value, event) => {
              event.originalEvent.stopPropagation();
              setTypeDrop(!typeDrop);
              setSignerDrop(false);
            }} id="body-dropdown-inputs">
            {inputs.map((inputType, index) => <Dropdown.Item key={index} onClick={() => changeInputType(index)}>
              {inputType}
            </Dropdown.Item>)}
          </DropdownButton>
          {isImg(input)
            ? <Button as={Link} to="draw" variant="outline-dark" className={`br-0 bg-w ${input.type == 5 && "d-flex fw-100"}`} onClick={() => setCurrentInput(null)}>
                {input.type == 5 ? <span className="material-icons-outlined sidebar-icon mr-1">switch_camera</span> : ""} Change {inputs[input.type]}
              </Button>
            : !isInputtype(input, "checkbox") &&
              <Form.Group className="body-inputs">
                <Form.Label className="mt-1 d-ib m-rl-5">Font size</Form.Label>
                <Form.Control type="number" min={6} max={30}
                  plaintext style={{width: "3rem"}} className="d-ib"
                  value={input.fontsize} onChange={changeFontsize} />
              </Form.Group>}
        </Popover.Body>
      </Popover>}>
      {renderInput()}
    </OverlayTrigger>
    <Button variant="light" className={`position-absolute bg-light rounded-circle p-1 border ${input.required && "circle-border"}`}
      style={{left: (input.x + input.width) * scale - 6, top: input.y * scale - 4}}
      onClick={event => event.stopPropagation()}
      onMouseDown={resizestart} />
  </>;
}
